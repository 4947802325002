import { PromatLogo, RuaudLogo, SedibelLogo } from "../constants/images";
import { useTranslation } from "react-i18next";

const partners = [
  { name: "Promat", logo: PromatLogo },
  { name: "Ruaud", logo: RuaudLogo },
  { name: "SediBel", logo: SedibelLogo },
];

const Partners = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-base font-semibold uppercase text-primary-900 tracking-wider">
          {t("Our partners")}
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          {partners.map((partner) => (
            <div
              key={partner.name}
              className="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
            >
              <img className="max-h-12" src={partner.logo} alt={partner.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
