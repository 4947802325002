import Logo from "./cbbc_logo.svg";
import {
  MailIcon,
  PhoneIcon,
  LocationMarkerIcon,
  VolumeUpIcon,
  CheckIcon,
  CurrencyDollarIcon,
  StarIcon,
} from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import {
  Lyautey1Img,
  Lyautey2Img,
  Lyautey3Img,
  Lyautey4Img,
  PassageFitness1Img,
  PassageFitness2Img,
  PassageFitness3Img,
  PassageFitness4Img,
  PeintureIntumescenteImg,
  ProjectionFibreseImg,
  ProjectionPateuseImg,
  Qualiconsult1Img,
  Qualiconsult2Img,
  Qualiconsult3Img,
  Sofitel1Img,
  Sofitel2Img,
} from "./constants/images";

const app = {
  name: "Low energy building design",
  name_fr: "Conception Bâtiment Basse Consommation",
  shortName: "CBBC",
  description:
    "The specialist in thermal, acoustic insulation and passive fire protection",
  description_fr:
    "Le spécialiste de l'isolation thermique, acoustique et de la protection passive incendie",
  logo: Logo,
};

const acFeatures = [
  {
    title: "Acoustic performance",
    title_fr: "Performance acoustique",
    content: () => (
      <>
        <span>
          Sound absorption α<sub>w</sub> = 0.55
        </span>
      </>
    ),
    content_fr: () => (
      <>
        <span>
          Absorption acoustique α<sub>w</sub> = 0.55
        </span>
      </>
    ),
    icon: VolumeUpIcon,
  },
  {
    title: "Aesthetic rendering",
    title_fr: "Rendu esthétique",
    content: () => (
      <>
        <span>
          Monolithic effect with very fine grain (hardly noticeable in ceiling
          from 6m high).
        </span>
      </>
    ),
    content_fr: () => (
      <>
        <span>
          Effet monolithique à grain très fin (à peine preceptible en plafond
          dès 6m de haut).
        </span>
      </>
    ),
    icon: StarIcon,
  },
  {
    title: "Practicality",
    title_fr: "Praticité",
    content: () => (
      <>
        <ul className="list-disc ml-6">
          <li>Application on flat and curved surfaces</li>
          <li>Consists of a single product and its primer</li>
          <li>
            Requires no accessories and can be mixed in a conventional spray
            machine
          </li>
        </ul>
      </>
    ),
    content_fr: () => (
      <>
        <ul className="list-disc ml-6">
          <li>Application sur surfaces planes et surfaces courbes</li>
          <li>Se compose d'un seul produit et de son primaire</li>
          <li>
            Ne nécessite aucun accessoire et se mélange dans une machine à
            projeter classique
          </li>
        </ul>
      </>
    ),
    icon: CheckIcon,
  },
  {
    title: "Performance",
    title_fr: "Rendement",
    content: () => (
      <>
        <span>
          With a spray solution that can fit into the smallest gap, you cover
          more area at a lower cost than other types of solutions.
        </span>
      </>
    ),
    content_fr: () => (
      <>
        <span>
          Grâce à sa solution projetée pouvant se glisser dans le plus petit
          interstice, vous couvrez plus de surface à moindre coût, comparé à
          d'autres types de solutions.
        </span>
      </>
    ),
    icon: CurrencyDollarIcon,
  },
];

const fpSafetyRegulations = [
  {
    text: "To ensure the safety and the evacuation of the occupants and all particularly of the ERP (Etablissement Recevant du Public)",
    text_fr:
      "D’assurer la sécurité et l’évacuation des occupants et tous particulièrement des ERP (Etablissement Recevant du Public)",
  },
  {
    text: "To facilitate the intervention of the firemen",
    text_fr: "De faciliter l’intervention des pompiers",
  },
  {
    text: "To ensure the stability of structural elements",
    text_fr: "D’assurer la stabilité des éléments structurels",
  },
  {
    text: "To limit the propagation of the fire",
    text_fr: "De limiter la propagation de l’incendie",
  },
  {
    text: "To avoid the transmission of the fire to the neighboring buildings",
    text_fr: "D’éviter la transmission de l’incendie aux bâtiments voisins",
  },
  {
    text: "To avoid the origin of the fire",
    text_fr: "D’éviter la naissance de l’incendie",
  },
  {
    text: "To reduce the operating losses",
    text_fr: "De réduire les pertes d’exploitation",
  },
];

const fpCategories = [
  {
    image: ProjectionPateuseImg,
    title: "Paste spraying",
    title_fr: "Projection pâteuse",
    subtitle: "A protection in paste form",
    subtitle_fr: "Une protection sous forme de pâte",
    description: () => (
      <>
        The projection in paste form is carried out after mixing the product
        with 50 % of water on healthy and waterproof supports, free of
        grease,...
      </>
    ),
    description_fr: () => (
      <>
        La projection sous forme pâteuse est réalisée après mélange du produit
        avec 50 % d’eau sur supports sains et hors d’eau, exempts de
        graisses,...
      </>
    ),
  },
  {
    image: ProjectionFibreseImg,
    title: "Fibrous projection",
    title_fr: "Projection fibreuse",
    subtitle: "Based on slag mineral wool",
    subtitle_fr: "A base de laine minérale de laitier",
    description: () => (
      <>
        Les produits que nous utilisons : Sont composés de laine minérale de
        laitier, de liants hydrauliques minéraux, et agent anti poussière.
        <br />
        Est exempt d’amiante.
        <br />
        Sont fabriqués exclusivement à partir de laine conforme à la directive
        97/69 CE (faible bio persistance ; produit non classé comme
        cancérigène).
        <br />
        Essais de résistance au feu réalisés conformément aux normes
        européennes.
      </>
    ),
    description_fr: () => (
      <>
        The products we use: Are composed of slag mineral wool, mineral
        hydraulic binders, and anti-dust agent.
        <br />
        Is free of asbestos.
        <br />
        Are manufactured exclusively from wool that complies with Directive
        97/69 EC (low bio persistence; product not classified as carcinogenic).
        <br />
        Fire resistance tests carried out in accordance with European standards.
      </>
    ),
  },
  {
    image: PeintureIntumescenteImg,
    title: "Intumescent paint",
    title_fr: "Peinture intumescente",
    subtitle: "For metal frames",
    subtitle_fr: "Pour les charpentes métalliques",
    description: () => (
      <>
        La projection sous forme pâteuse est réalisée après mélange du produit
        avec 50 % d’eau sur supports sains et hors d’eau, exempts de
        graisses,...
      </>
    ),
    description_fr: () => (
      <>
        The projection in paste form is carried out after mixing the product
        with 50% of water on healthy and waterproof supports, free of grease,...
      </>
    ),
  },
];

const iaReferences = [
  {
    title: "Head office of Qualiconsult Sidi Maarouf",
    title_fr: "Siège social de Qualiconsult Sidi Maarouf",
    images: [Qualiconsult1Img, Qualiconsult2Img, Qualiconsult3Img],
  },
  {
    title: "Night club of Sofitel Rabat Jardin des Roses",
    title_fr: "Night club de Sofitel Rabat Jardin des Roses",
    images: [Sofitel1Img, Sofitel2Img],
  },
  {
    title: "CDI Lyautey High School",
    title_fr: "CDI Lycée Lyautey",
    images: [Lyautey1Img, Lyautey2Img, Lyautey3Img, Lyautey4Img],
  },
  {
    title: "Gym Passage Fitness Ain Diab",
    title_fr: "Salle de sport Passage Fitness Ain Diab",
    images: [
      PassageFitness1Img,
      PassageFitness2Img,
      PassageFitness3Img,
      PassageFitness4Img,
    ],
  },
];

const services = [
  // Acoustic Correction
  {
    title: "Acoustic Correction",
    title_fr: "Correction Acoustique",
    subtitle: "Decorative coating of acoustic absorption",
    subtitle_fr: "Enduit decoratif d'absorption acoustique",
    route: "/acoustic-correction",
    slug: "acoustic-correction",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <rect x="11.36" width="2.27" height="24" />
        <path d="M.75,15.15l-.75-.75c1.31-1.31,1.31-3.44,0-4.75l.75-.75c.83,.83,1.3,1.95,1.3,3.12,0,1.17-.46,2.3-1.3,3.12Z" />
        <path d="M2.52,16.78l-.75-.75c2.21-2.22,2.21-5.8,0-8.02l.75-.75c2.62,2.63,2.62,6.89,0,9.51Z" />
        <path d="M4.27,18.49l-.75-.74c3.16-3.16,3.16-8.29,0-11.45l.75-.75c3.57,3.57,3.57,9.37,0,12.94Z" />
        <path d="M6.02,20.23l-.75-.75c4.11-4.11,4.11-10.81,0-14.93l.75-.75c4.53,4.53,4.53,11.9,0,16.42Z" />
        <path d="M15.65,12.94l-.22-.22c.39-.39,.39-1.01,0-1.4l.22-.22c.51,.51,.51,1.33,0,1.83h0Z" />
        <path d="M16.17,13.42l-.22-.22c.65-.65,.65-1.71,0-2.36l.22-.22c.77,.77,.77,2.02,0,2.8Z" />
        <path d="M16.69,13.93l-.22-.22c.93-.93,.93-2.44,0-3.37l.22-.22c1.05,1.05,1.05,2.75,0,3.81Z" />
        <path d="M17.2,14.44l-.22-.22c1.21-1.21,1.21-3.18,0-4.39l.22-.22c1.33,1.33,1.33,3.49,0,4.83Z" />
      </svg>
    ),
    description:
      "Acoustic absorption allows to attenuate the effects of reverberation for an ambient quality particularly appreciated by restaurants, cafes, nightclubs, stages, theaters...",
    description_fr:
      "L'absorption acoustique permet d'atténuer les effets des réverbération pour une qualité ambiante particulièrement prisée les restaurants café, nightclubs, scènes, théâtres..",
    content: () => (
      <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        {acFeatures.map((feature, key) => (
          <div key={"feature-" + key} className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-100 text-primary-900">
                <feature.icon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                {feature.title}
              </p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              <feature.content />
            </dd>
          </div>
        ))}
      </dl>
    ),
    content_fr: () => (
      <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        {acFeatures.map((feature, key) => (
          <div key={"feature-fr-" + key} className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-100 text-primary-900">
                <feature.icon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                {feature.title_fr}
              </p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              <feature.content_fr />
            </dd>
          </div>
        ))}
      </dl>
    ),
  },
  // Thermal Insulation
  {
    title: "Thermal Insulation",
    title_fr: "Isolation Thermique",
    subtitle: "Conserve your energy!",
    subtitle_fr: "Conservez votre énergie !",
    route: "/thermal-insulation",
    slug: "thermal-insulation",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M24,12.58v-1.16h-3.49c-.05-.74-.2-1.47-.43-2.17,0,0,0,.02,0,.03l3.23-1.34-.44-1.07-3.23,1.34h0c-.33-.65-.74-1.26-1.22-1.81l2.47-2.47-.82-.82-7.49,7.5V4.47l2.98-2.98-.82-.82-2.16,2.16V0h-1.16V2.83L9.26,.67l-.82,.82,2.98,2.98v6.13L7.08,6.26V2.05h-1.16v3.05l-2-2-.82,.82,2.01,2.01H2.06v1.16H6.27l4.34,4.34H4.47l-2.98-2.98-.82,.82,2.16,2.16H0v1.16H2.83L.67,14.74l.82,.82,2.98-2.98h6.13l-7.49,7.49,.82,.82,2.47-2.47c.55,.48,1.16,.89,1.82,1.22h0l-1.34,3.23,1.07,.44,1.34-3.23s-.02,0-.03,0c.7,.24,1.43,.39,2.17,.43v3.49h1.15v-3.49c.73-.05,1.45-.19,2.14-.42h0l1.34,3.23,1.07-.44-1.34-3.23h0c.65-.33,1.26-.73,1.81-1.21h0l2.47,2.47,.82-.82-2.47-2.47h0c.48-.55,.89-1.16,1.21-1.81h0l3.23,1.33,.44-1.07-3.23-1.34h0c.23-.69,.38-1.41,.42-2.14h3.5Zm-11.81,6.78c-1.82,.05-3.59-.58-4.96-1.76L17.6,7.22c1.18,1.38,1.81,3.15,1.76,4.96-.09,3.91-3.27,7.09-7.18,7.18Z" />
      </svg>
    ),
    description:
      "Thermal insulation limits heat transfers between a warm and a cold environment by reducing exchanges between the interior of a building and its external environment.",
    description_fr:
      "L’isolation thermique limite les transferts de chaleur entre une ambiance chaude et une ambiance froide en diminuant les échanges entre l’intérieur d’un bâtiment et son environnement extérieur.",
    content: () => <></>,
  },
  // Fire Protection
  {
    title: "Fire Protection",
    title_fr: "Protection Incendie",
    subtitle: "Passive fire protection solutions",
    subtitle_fr: "Les solutions de protection passive",
    route: "/fire-protection",
    slug: "fire-protection",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M0,10.22V6.08c0-.34,.07-.42,.39-.53,1.73-.57,3.38-1.29,4.81-2.44,1.02-.82,1.92-1.76,2.74-2.77l.11-.14c.21-.26,.46-.25,.67,0,.35,.42,.7,.83,1.06,1.24,1.58,1.77,3.52,3.02,5.74,3.82,.29,.1,.59,.2,.88,.3,.28,.09,.36,.19,.36,.48,0,.72,0,1.44,0,2.16,0,1.89-.02,3.78,0,5.67,.04,2.33-.87,4.25-2.45,5.89-.59,.62-1.27,1.15-1.98,1.62-1.21,.8-2.4,1.63-3.6,2.45-.32,.22-.39,.22-.7,0-1.31-.89-2.63-1.76-3.92-2.67-1.66-1.17-2.97-2.62-3.68-4.56C.15,15.84,0,15.02,0,14.21,0,13.14,0,12.08,0,11.01v-.79ZM.76,6.21V14.26c0,.78,.15,1.55,.43,2.27,.6,1.56,1.66,2.77,2.98,3.76,.76,.57,1.57,1.08,2.35,1.62,.61,.41,1.23,.83,1.85,1.25,.03-.02,.06-.03,.09-.05,1.24-.84,2.47-1.68,3.72-2.52,.72-.49,1.37-1.06,1.95-1.72,1.23-1.42,1.91-3.04,1.89-4.94-.03-2.51,0-5.01,0-7.52v-.2c-3.1-.94-5.63-2.68-7.63-5.25C6.39,3.52,3.85,5.27,.76,6.21Z" />
        <path d="M7.47,12.94c-.05-.34-.11-.69-.13-1.03-.09-1.59,.6-2.81,1.81-3.77,.93-.74,2-1.2,3.14-1.53,.02,0,.05,0,.07,0,.07,0,.13,.02,.2,.03-.01,.07,0,.15-.04,.2-.32,.46-.56,.97-.7,1.5-.51,1.91-.56,3.82,.12,5.71,.3,.83,.37,1.68,.09,2.53-.23,.7-.68,1.32-1.27,1.76-.45,.34-.95,.61-1.44,.91-.06,.02-.13,.02-.2,0-.02-.01-.03-.13,0-.17,.5-.82,.5-1.7,.28-2.59-.14-.53-.37-1.03-.56-1.55-.02-.04-.04-.08-.07-.11-.12,.7-.31,1.36-.69,1.94-.05,.08-.09,.2-.21,.15s-.07-.17-.04-.27c.19-.62,.26-1.25,.07-1.87-.25-.8-.81-1.31-1.53-1.69-.11-.06-.23-.11-.35-.17,.09,.32,.19,.62,.25,.93,.2,1.08,.19,2.16-.13,3.23-.16,.53-.15,1.07,.08,1.59,.02,.05-.01,.13-.02,.2-.07-.01-.15,0-.2-.04-.92-.73-1.54-1.66-1.74-2.84-.2-1.16,0-2.27,.39-3.36,.39-1.09,.94-2.11,1.62-3.05,0-.01,.02-.03,.03-.04,.07-.04,.14-.07,.21-.1,.02,.07,.06,.14,.06,.21,0,.14-.03,.28-.03,.42,.01,.71,.23,1.38,.5,2.03,.12,.3,.28,.59,.41,.89,0,0,.01,0,.02-.01Z" />
      </svg>
    ),
    description:
      "Passive fire protection is a key factor in the construction or retrofitting of a building.",
    description_fr:
      "La protection passive contre l’incendie constitue un facteur déterminant lors de la construction ou de la mise en conformité d’un bâtiment.",
    content: () => (
      <div>
        <div className="space-y-6 text-gray-600">
          <p className="text-gray-900">
            The installation of an active protection (sprinklers,
            extinguishers...) is mandatory, necessary, but not sufficient.
            <br />
            As a professional in passive fire protection, we help you to comply
            with the safety regulations for buildings.
          </p>
          <div>
            <p className="font-medium text-gray-900">
              The safety regulations aim to :
            </p>
            <div className="mt-4 space-y-4">
              {fpSafetyRegulations.map((regulation, key) => (
                <div className="flex items-start ">
                  <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary-500" />
                  <p key={"regulation-" + regulation + key} className="ml-2">
                    {regulation.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <p>
            The first objective is to allow the evacuation of people in the best
            possible conditions.
          </p>
          <p>
            Some disasters can also cause irreparable damage to a building,
            leading to a total shutdown of a company's activity.
          </p>
          <p>
            The passive protection acts by its only presence, without human
            intervention nor need of energy, it does not require any
            maintenance.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {fpCategories.map((item, key) => (
            <div
              key={"category-" + key}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={item.image}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <h3 className="text-xl font-bold text-primary-900">
                    {item.title}
                  </h3>
                  <p className="mt-1 text-sm font-semibold text-gray-900">
                    {item.subtitle}
                  </p>
                  <p className="mt-3 text-sm text-gray-500">
                    <item.description />
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
    content_fr: () => (
      <div>
        <div className="space-y-6 text-gray-600">
          <p className="text-gray-900">
            L’installation d’une protection active (sprinklers, extincteurs…)
            est obligatoire, nécessaire, mais elle ne suffit pas.
            <br />
            En tant que professionnel de la protection passive incendie, nous
            vous aidons à vous conformer aux normes de sécurité pour les
            bâtiments.
          </p>
          <div>
            <p className="font-medium text-gray-900">
              Les règlements de sécurité ont pour but :
            </p>
            <div className="mt-4 space-y-4">
              {fpSafetyRegulations.map((regulation, key) => (
                <div className="flex items-start ">
                  <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary-500" />
                  <p key={"regulation-fr-" + regulation + key} className="ml-2">
                    {regulation.text_fr}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <p>
            L’objectif premier est de permettre l’évacuation des personnes dans
            les meilleures conditions qui soient.
          </p>
          <p>
            Certains sinistres peuvent également entrainer des dégâts
            irrémédiables pour un bâtiment, entrainant un arrêt total de
            l'activité d'une société.
          </p>
          <p>
            La protection passive agit par sa seule présence, sans intervention
            humaine ni besoin en énergie, elle ne nécessite aucun entretien..
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {fpCategories.map((item, key) => (
            <div
              key={"category-fr" + key}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={item.image}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <h3 className="text-xl font-bold text-primary-900">
                    {item.title_fr}
                  </h3>
                  <p className="mt-1 text-sm font-semibold text-gray-900">
                    {item.subtitle_fr}
                  </p>
                  <p className="mt-3 text-sm text-gray-500">
                    <item.description_fr />
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
  },
  // Acoustic Insulation
  {
    title: "Acoustic Insulation",
    title_fr: "Isolation Acoustique",
    subtitle: "By acoustic insulation of buildings",
    subtitle_fr: "Par isolation acoustique des batiment",
    route: "/acoustic-insulation",
    slug: "acoustic-insulation",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M14.69,24h-1.16c0-.24-.25-.4-.76-.7-.57-.32-1.35-.77-1.35-1.71s.78-1.38,1.35-1.71c.51-.29,.76-.46,.76-.7s-.25-.4-.76-.69c-.57-.32-1.35-.77-1.35-1.71s.78-1.38,1.35-1.71c.51-.29,.76-.46,.76-.69s-.25-.4-.76-.69c-.57-.32-1.35-.77-1.35-1.71s.78-1.38,1.35-1.71c.51-.29,.76-.46,.76-.69s-.25-.4-.76-.69c-.57-.32-1.35-.77-1.35-1.71s.78-1.38,1.35-1.71c.51-.29,.76-.46,.76-.69s-.25-.4-.76-.69c-.57-.32-1.35-.77-1.35-1.71s.78-1.38,1.35-1.71c.51-.28,.76-.45,.76-.69h1.16c0,.94-.78,1.38-1.35,1.71-.51,.29-.76,.46-.76,.69s.25,.4,.76,.69c.57,.32,1.35,.77,1.35,1.71s-.78,1.38-1.35,1.71c-.51,.29-.76,.46-.76,.69s.25,.4,.76,.69c.57,.32,1.35,.77,1.35,1.71s-.78,1.38-1.35,1.71c-.51,.29-.76,.46-.76,.69s.25,.4,.76,.69c.57,.32,1.35,.77,1.35,1.71s-.77,1.38-1.35,1.71c-.51,.29-.76,.46-.76,.69s.25,.4,.76,.69c.57,.32,1.35,.77,1.35,1.71s-.78,1.38-1.35,1.71c-.51,.29-.76,.46-.76,.7s.25,.4,.76,.7c.57,.32,1.35,.76,1.35,1.7Z" />
        <path d="M.75,15.12l-.75-.75c1.31-1.31,1.31-3.43,0-4.74l.75-.75c.83,.83,1.3,1.95,1.29,3.12,0,1.17-.46,2.29-1.29,3.12Z" />
        <path d="M2.51,16.75l-.75-.75c2.21-2.21,2.21-5.79,0-8l.75-.75c2.62,2.62,2.62,6.88,0,9.5Z" />
        <path d="M4.26,18.46l-.75-.74c3.15-3.15,3.15-8.28,0-11.43l.75-.75c3.56,3.56,3.56,9.35,0,12.92Z" />
        <path d="M6,20.19l-.75-.75c4.11-4.11,4.11-10.79,0-14.9l.75-.75c4.52,4.52,4.52,11.87,0,16.39Z" />
      </svg>
    ),
    description:
      "The acoustic insulation or phonic allows to avoid the propagation of the solidian and vibratory noise",
    description_fr:
      "L'isolation acoustique ou phonique permet d'éviter la propagation du bruit solidien et vibratoire.",
    content: () => (
      <div className="space-y-6">
        <p>
          CBBC studies all the solutions adapted to your project thanks to a
          great diversified experience.
        </p>
        <p>Some references :</p>
        <div className="space-y-6">
          {iaReferences.map((reference, key) => (
            <div key={"reference-" + key}>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start">
                  <span className="pr-2 bg-white text-sm text-gray-500">
                    {reference.title}
                  </span>
                </div>
              </div>
              <div className="mt-5 grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                {reference.images.map((image, index) => (
                  <img
                    key={"image" + index}
                    className="h-32 md:h-48 w-full object-cover rounded"
                    src={image}
                    alt=""
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
    content_fr: () => (
      <div className="space-y-6">
        <p>
          CBBC étudie toutes les solutions adaptées à votre projet grace à une
          grande expérience diversifiée.
        </p>
        <p>Quelque références :</p>
        <div className="space-y-6">
          {iaReferences.map((reference, key) => (
            <div key={"reference-" + key}>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start">
                  <span className="pr-2 bg-white text-sm text-gray-500">
                    {reference.title_fr}
                  </span>
                </div>
              </div>
              <div className="mt-5 grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                {reference.images.map((image, index) => (
                  <img
                    key={"image" + index}
                    className="h-32 md:h-48 w-full object-cover rounded"
                    src={image}
                    alt=""
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
  },
  // Technical Screed
  {
    title: "Technical Screed",
    title_fr: "Chape Thechnique",
    subtitle: "Insulating floating screed",
    subtitle_fr: "Chape flottante isolante",
    route: "/technical-screed",
    slug: "technical-screed",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <rect y="6.54" width="24" height="2.48" />
        <rect y="3.58" width="24" height=".19" />
        <rect width="24" height=".76" />
        <circle cx="4.89" cy="5.68" r=".81" />
        <circle cx="12" cy="5.68" r=".81" />
        <circle cx="19.11" cy="5.68" r=".81" />
        <circle cx="2.27" cy="2.76" r=".81" />
        <circle cx="7.13" cy="2.77" r=".81" />
        <circle cx="12" cy="2.77" r=".81" />
        <circle cx="16.87" cy="2.78" r=".81" />
        <circle cx="21.73" cy="2.78" r=".81" />
      </svg>
    ),
    description:
      "We make 3 types of screeds: Acoustic screed, liquid screed, semi-dry screed.",
    description_fr:
      "Nous realisons 3 types des chapes: Chape acoustique, chape liquide, chape semi sèche.",
    content: () => <></>,
  },
];

const flocage = {
  title: "What is flocking ?",
  title_fr: "Qu’est-ce que le flocage ?",
  subtitle: "Discover the advantages of this process",
  subtitle_fr: "Découvrez les avantages de ce procédé",
  description:
    "Flocking consists in applying by projection fiber or paste based products thanks to a binder allowing a perfect adherence.",
  description_fr:
    "Le flocage consiste à appliquer par projection de produits à base de fibre ou de pâte à grâce à un liant permettant une adhérence parfaite.",
  details: () => (
    <>
      <p className="text-lg font-bold text-white">
        An ecological product
        <br />
        <span className="italic font-medium text-white">
          with multiple virtues...
        </span>
      </p>
    </>
  ),
  details_fr: () => (
    <>
      <p className="text-lg font-bold text-white">
        Un produit écologique
        <br />
        <span className="italic font-medium text-white">
          aux multiples vertus...
        </span>
      </p>
    </>
  ),
};

const contacts = [
  {
    title: "address",
    title_fr: "Adresse",
    icon: LocationMarkerIcon,
    text: "Km 3, Route el Gara, Berrechid. Morocco",
    text_fr: "Km 3, Route el Gara, Berrechid. Maroc",
  },
  {
    title: "phone",
    title_fr: "téléphone",
    icon: PhoneIcon,
    text: "+212 520 164163",
    text_fr: "+212 520 164163",
  },
  {
    title: "email",
    title_fr: "email",
    icon: MailIcon,
    text: "contact@cbbc.africa",
    text_fr: "contact@cbbc.africa",
  },
];

const aboutUs = {
  text: () => (
    <>
      <p>
        <span className="text-primary-900 font-semibold">C.B.B.C</span> Partner
        of all your projects to treat your operations in fireproofing, thermal
        insulation, acoustic and technical screed.
      </p>
      <p>
        Our approach has for first objective the satisfaction of our customers
        in an environmental quality approach.
        <br />
        We offer you the best technical solutions, according to rules and
        standards in force and your requirements. Our teams are at your service
        throughout Africa.
      </p>
      <p>
        The executives of our company have worked for more than 30 years within
        the majors of the btp in France and internationally, they are supported́
        by a technical direction and an experienced BET.
      </p>
      <p>
        Our works teams are experienced and continuously trained in our fields
        of application, they perfectly master interventions in occupied or
        sensitive sites. They will guarantee you quality services and the
        assurance of a perfect completion of your operations.
      </p>
      <p>
        Our objective is to accompany you in your projects since the
        elaborations of the program and the studies until the execution .<br />
        For that you can count on CBBC as well in the reactivity for the
        productions in the short term as in the availability for your
        realizations in the longer term.
      </p>
      <p>
        We are at your disposal for any studies and information you may need.
      </p>
    </>
  ),
  text_fr: () => (
    <>
      <p>
        <span className="text-primary-900 font-semibold">C.B.B.C</span>{" "}
        Partenaire de tous vos projets pour traiter vos opérations en protection
        passive au feu , l’isolation thermique, acoustique et chape technique.
      </p>
      <p>
        Notre démarche a pour objectif premier la satisfaction de nos clients
        dans une démarche de qualité environnementale.
        <br />
        Nous vous proposons les meilleures solutions techniques, selon règles et
        normes en vigueur et vos impératifs. Nos équipes sont à votre service
        sur toute l'afrique.
      </p>
      <p>
        Les cadres de notre société ont œuvré pendant plus de 30 ans au sein des
        majors du btp en France et à l’international, ils sont épaulé́ par une
        direction technique et un BET expérimentés.
      </p>
      <p>
        Nos équipes travaux sont expérimentées et continuellement formées dans
        nos domaines d'applications, elles maîtrisent parfaitement les
        interventions en sites occupés ou sensibles. Elles vous garantiront des
        prestations de qualités et l'assurance d'un parfait achèvement de la
        réalisation de vos opérations.
      </p>
      <p>
        Notre objectif est de vous accompagner dans vos projets depuis
        l’élaborations du programme et les études jusqu'à l’exécution .<br />
        Pour cela vous pourrez compter sur CBBC tant dans la réactivité pour les
        productions à court terme que dans la disponibilité pour vos
        réalisations à plus long terme.
      </p>
      <p>
        Nous sommes à votre écoute pour toutes études et renseignements dont
        vous auriez besoin.
      </p>
    </>
  ),
};

export { app, services, flocage, contacts, aboutUs };
