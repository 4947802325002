import { AboutImg } from "../constants/images";
import { useTranslation } from "react-i18next";
import { aboutUs as aboutUsData } from "../data";

const About = () => {
  const [t, i18n] = useTranslation();

  const aboutUs =
    i18n.language === "fr" ? { text: aboutUsData.text_fr } : aboutUsData;

  return (
    <div className="relative py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="">
          <h2 className="mt-2 text-3xl font-extrabold text-primary-900 tracking-tight sm:text-4xl">
            {t("About us")}
          </h2>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2 lg:pl-12">
            <img
              src={AboutImg}
              alt=""
              className="w-full lg:h-full h-60 object-cover bg-gray-100 rounded-lg"
            />
          </div>
          <div className="space-y-4 mt-6 lg:mt-0 text-justify text-gray-500">
            <aboutUs.text />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
